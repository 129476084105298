<template>
  <div class="about">
    <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in swipeImg" :key="index"><img :src="item" width="100%" height="100%"></van-swipe-item>
    </van-swipe>
    <div class="info">
      <van-row>
        <van-col span="12" class="border-right"><span v-text="count.online"></span><p>在线</p></van-col>
        <van-col span="12"><span >{{count.total}}</span><p>全部</p></van-col>
      </van-row>
    </div>
    <div class="dev-list">
      <div v-if="!devEmpty">
          <div v-if="devList.length != 0">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
                <div class="block" v-for="(item,index) in devList" :key="index" @click="goDetail(item)">
                  <van-row :class="{
                      'flex-end':true,
                      'online':item.devStatus == 'online',
                      'offline':item.devStatus == 'offline',
                      'alarm':item.devStatus == 'alarm'
                    }">
                      <van-col span="6">
                          <img :src="
                            item.devStatus === 'online' ? require('@/assets/720cam/img/yellow.png') :  
                            item.devStatus === 'alarm' ? require('@/assets/720cam/img/yellow.png') : require('@/assets/720cam/img/default.png')
                          " width="30px" height="70px">
                      </van-col>
                      <van-col span="18">
                          <div class="title" v-text="item.devName"></div>
                          <div v-text="item.location || '设备暂未设置地址'"></div>
                      </van-col>
                  </van-row>
                </div>
            </van-list>
          </div>
          <div v-else><van-loading class="z-loading" size="24px" style="margin:20vh 0" vertical>数据努力加载中...</van-loading></div>
      </div>
      <div v-else><van-empty description="设备列表为空" /></div>
      </div>
    <tabbar />
  </div>
</template>

<script>
import tabbar from '@/components/720cam/tabBar.vue'

export default {
  name: 'devIndex',
  components: {
    tabbar
  },
  data(){
    return{
      swipeImg:[
        require('@/assets/720cam/img/banner1.jpg'),
        require('@/assets/720cam/img/banner2.jpg'),
        require('@/assets/720cam/img/banner3.jpg'),
        require('@/assets/720cam/img/banner4.jpg'),
        require('@/assets/720cam/img/banner5.jpg')
      ],
      count:{
        online:0,
        total:0
      },
      projectCode:'',
      loading:false,
      finished:false,
      pageSize:10,
      pageNum:1,
      devList:[],
      devEmpty:false,
      isLoading: false,
    }
  },
  methods:{
      // 点击设备进入详情页面
      goDetail(item){
        window.sessionStorage.setItem('dev-info',JSON.stringify(item))
        // this.$router.push({path:'/720cam/detail',query: {devCode:item.devCode}})
        this.$router.push({path:'/720cam/detail'})
      },
      // 获取分页数据信息
      onLoad(){
        this.$api.CAMERA.getAllDev({projectCode:this.projectCode}).then( d =>{
          if(d.length > 0){
            this.loading = false;
            this.devList = d;
            this.finished = true;
          }else{
            this.devEmpty = true;
          }
          this.count = {
            online:d.filter( v => v.devStatus !== 'offline').length,
            total:d.length,
          }
        })
      },
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.onLoad();
  }
}
</script>
<style lang="scss" scoped>
.info{
    padding: 4%;
    background: #3d6561;
    color: white;
    margin-top: -2%;
    .border-right{border-right:1px solid #989898ad}
    span{font-size: 1.5rem;font-weight: bold;}
    p{margin: 0;font-size: .9rem;}
}
.dev-list{
    margin-bottom: 22%;
    .block{
        border: 1px solid #f5f5f5;
        background-color: white;
        padding: 4%;
        margin-bottom: 10px;
        font-size: .9rem;
        text-align: left;
        box-shadow: 0 2px 4px #e4e4e4;
        .online{color:#1f7460;}
        .offline{color:#5d5e5f;}
        .alarm{color:#de0101;}
        img{display: block;margin: 0 auto;}
        .title{
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
    .flex-end{
      display: flex;
      align-items: center;
    }
}
</style>